@import "_variables.scss";

.ic-completed-container {
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: var(--chakra-space-8);
    margin-bottom: var(--chakra-space-24);
    width: 60%;
    .title {
      line-height: 32px;
      margin-bottom: var(--chakra-space-3-2);
    }
    .subtitle {
      font-size: $font-size-med;
      line-height: 24px;
    }
  }
  .content-container {
    width: 65%;
    margin: 0 auto;
    margin-top: var(--chakra-space-24);

    .content-heading {
      line-height: 28px;
      margin-bottom: var(--chakra-space-3-2);
    }
    .content-subtitle {
      font-size: $font-size-reg;
      font-weight: 400;
      line-height: 24px;
    }

    .custom-accordion-item {
      border: 0px;
      .custom-accordion-item-header {
        display: flex;
        justify-content: space-between;
      }
      .chakra-collapse {
        overflow: visible !important;
      }

      .custom-accordion-item-left {
        display: flex;
        gap: 20px;
        align-items: flex-start;
      }

      .accordion-item-title {
        font-size: $font-size-2rem;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-large;
        margin-bottom: var(--chakra-space-3-2);
      }
      .accordion-item-subtitle {
        margin-bottom: var(--chakra-space-4);
        font-size: $font-size-reg;
        color: $color-font-card;
        width: 100%;
      }
      .accordion-item-button {
        justify-content: flex-end;
        align-items: flex-start;
        width: auto;
        height: var(--chakra-sizes-8);
        &:hover {
          background: none;
        }
      }
      .accordion-item-badge-holder {
        display: flex;
        gap: $gap-small;
      }

      .inprogress-heading {
        font-size: $font-size-small;
        font-family: $font-family-sfmono !important;
        line-height: 20px;
      }

      .inprogress-item {
        margin: 20px 0;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid $color-border;
        gap: 24px;
        display: flex;
        justify-content: space-between;
        .inprogress-item-left {
          display: flex;
          gap: 10px;
          align-items: flex-start;
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $color-font-card;
          }
        }
        .inprogress-item-right {
          display: flex;
          gap: 20px;
          align-items: center;
          .right-icon {
            height: 1.8rem;
            width: 1.8rem;
          }
        }
      }
      .accordion-table-container {
        display: flex;
        flex-direction: column;
        padding-top: $padding-xxl;

        .accordion-table-add-btn {
          text-align: right;
          margin-bottom: var(--chakra-sizes-8);
          margin-right: 0;
        }
        .ellipsis-button {
          width: var(--chakra-space-9-6);
          font-size: $font-base-size;
          border: 0px;
          cursor: pointer;
          .ellipsis-icon {
            height: 24px;
            width: 24px;
          }
          &:hover {
            background-color: $color-sidebar-hover-bg;
            .ellipsis-icon path {
              fill: $color-link-hover-color;
            }
          }

          &[data-active] {
            background-color: $color-sidebar-hover-bg;
            .ellipsis-icon path {
              fill: $color-link-hover-color;
            }
          }
        }
        .menu-list-container {
          width: 120px; // Width of the dropdown menu
          max-height: 400px;
          overflow-y: auto;
          box-shadow: 0 4px 8px $color-toast-box-shadow;
          border-radius: 20px;
          padding: 10px;

          .dropdown-menu-item {
            display: flex;
            align-items: center;
            border-radius: 20px;
            height: 50px;
            &:hover {
              background-color: $color-sidebar-hover-bg;
              border-radius: $border-radius-small;
            }
            &:focus {
              background-color: $color-sidebar-hover-bg;
              border-radius: $border-radius-small;
            }
            .menu-image {
              width: 24px;
              height: 24px;
              margin-right: 15px;
              margin-left: 20px;
            }

            .menu-text {
              color: $color-default-font-color;
              font-family: $font-family-semi-bold;
              font-size: $font-size-reg;
              font-weight: 700;
              letter-spacing: 0.32px;
              line-height: var(--chakra-lineHeights-7);
              &:hover {
                background-color: transparent;
              }
              &.delete-menu {
                color: $color-default-danger;
              }
            }
            .menu-text-delete-item {
              color: $color-border-error;
              font-weight: 700;
              font-size: $font-size-reg;
              line-height: var(--chakra-lineHeights-7);
            }
          }
        }
        .accordion-table-data {
          margin-top: var(--chakra-space-2);
          thead {
            tr {
              background-color: $color-bg-badge;
              td {
                font-size: $font-size-reg;
                font-weight: 500;
                text-transform: none;
                color: $color-default-black;
                padding: var(--chakra-space-6-4) var(--chakra-space-4);
              }
              .custom-table-header-data-item {
                width: 100%;
                font-size: $font-size-reg !important;
              }
              .custom-table-header-data {
                cursor: pointer;
              }
            }
          }
        }
      }
      .content-divider {
        position: absolute;
        left: 0;
        right: 0;
        border: 1px solid $color-border;
        &.orange {
          border-color: $color-gradient-light-orange;
        }
      }
      .glpositionGrid{
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: var(--chakra-space-25);
        grid-row-gap: var(--chakra-space-8);
      }
    }
  }
}
.more-option-container {
  display: flex;
  justify-content: flex-end;
}
.custom-card-container {
  padding: var(--chakra-space-12-8);
}
