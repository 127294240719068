@import "_variables.scss";

.code-block-slice {
  .code-block-wrap {
    display: flex;
  }

  .cm-theme {
    overflow: auto;
    width: -webkit-fill-available;
    font-family: $font-family-sfmono;
    font-size: $font-size-reg;
    font-weight: $font-weight-regular;
    line-height: $line-height-medium;
    border-bottom-left-radius: var(--chakra-space-3-2);
    border-bottom-right-radius: var(--chakra-space-3-2);
    border-top-left-radius: var(--chakra-space-3-2);
    border-top-right-radius: var(--chakra-space-3-2);

    .cm-editor {
      background-color: $color-default-font-color;
      border-left: 1px;
      border-bottom: 1px;
      border-right: 1px;
      border-color: $color-link-hover-color;
      border-bottom-left-radius: var(--chakra-space-3-2);
      border-bottom-right-radius: var(--chakra-space-3-2);
      border-top-left-radius: var(--chakra-space-3-2);
      border-top-right-radius: var(--chakra-space-3-2);
      color: $color-default-white;

      .cm-gutters {
        background-color: $color-default-font-color;
        border-bottom-left-radius: var(--chakra-space-3-2);
        color: $color-default-white;
      }

      .cm-scroller {
        padding: var(--chakra-space-4) 0px var(--chakra-space-9-6) 0px;
        border-bottom-right-radius: var(--chakra-space-3-2);
        border-top-left-radius: var(--chakra-space-3-2);
        border-top-right-radius: var(--chakra-space-3-2);
      }
    }
  }
}
